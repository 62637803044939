import React, { Fragment, useState, useEffect } from 'react';
import Lib from '../Lib'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChartWithVolume from './ChartWithVolume'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
//

//
import {withRouter} from '../withRouter'
import imageBase from '../style/imageBase'

import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgb(145,150,165)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'rgb(121,118,172)',
  },
}));

const SrcComponent = (props) => {
	//
	const [mode, setMode] = useState(false);
  const handleChangeMode = (value) => {
		if(value === 1){
			setMode(false);
		} else {
			setMode(true);
		}
  };
	//
	const [interval, setInterval] = useState('1D');
  const handleChangeInterval = (event) => {
    setInterval(event.target.value);
  };
	//
	const [clean, setClean] = useState(200);
	//
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('lg'));
	const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
	//
	const [data, setData] = useState([]);
	const [dataOrigin, setDataOrigin] = useState([]);
	//
	//Origin Data
	const [checked, setChecked] = React.useState([true, true]);
  const handleChange1 = (event) => {
		if(!checked[1]){
		} else {
    	setChecked([event.target.checked, checked[1]]);
		}
  };
  const handleChange2 = (event) => {
		if(!checked[0]){
		} else {
			setChecked([checked[0], event.target.checked]);
		}

  };
	const handleChangeMarket = (value) => {
		if(value === 0){
			setChecked([true, true]);
		} else if(value === 1){
			setChecked([true, false]);
		} else if(value === 2){
			setChecked([false, true]);
		}
	}

	//
  useEffect(() => {
		//
		if(props.data && props.data.txs){
			//
			var dataServer = props.data.txs.filter(x => (x.type === 'openstamp' && checked[0]) || (x.type === 'stampscan' && checked[1]))
			//

			Lib.cleanData(dataServer, clean).then((dataClean) => {

				var datafiltered = dataClean;
				if(interval == '1H'){
					 function filterTransactions(transactions){
						  const currentDate = new Date();
						  const oneMonthAgo = new Date();
						  oneMonthAgo.setMonth(currentDate.getMonth() - 1);

						  return transactions.filter((transaction) => {
						    const transactionDate = new Date(transaction.date);
						    return transactionDate > oneMonthAgo;
						  });
						};
					 datafiltered = filterTransactions(dataClean);
				}
				Lib.createData(datafiltered, interval).then((dataFinal) => {
					//
					setData(dataFinal[0])
					setDataOrigin(dataServer)
				});
			});
		}


  }, [props.data, interval, checked]);
	//
	var percentTxs = 50
	var percentAmount = 50
	if(props.data && props.data.total){
		const percentageTxsOpen = (props.data.total.tTopen / props.data.total.totalTxs) * 100;
		//const percentageScan = (scan / total) * 100;
		percentTxs = percentageTxsOpen;

		var percentageAmountOpen = (props.data.total.tAopen / props.data.total.totalAmount) * 100;
		//const percentageScan = (scan / total) * 100;
		percentAmount = percentageAmountOpen;
	}


	return <div style={{display:'flex',flexDirection:'column', justifyContent: matches ? 'center' : 'left', paddingBottom: 100}}>


						<Paper elevation={2} style={{
							display:'flex',
							alignItems:'center',
							justifyContent:'space-between',
							top: matches ? 0 : isSmall ? isMedium ? 120 : 80 : 60,
							position: matches ? 'relative' : 'absolute',
							left:0,
							width: matches ? 1200 : '100%',
							maxWidth: 1200,
							borderRadius: matches ? 4 : 0,
							height: isSmall ? isMedium ? 120 : 100 : 80,
						}}>
							<div style={{display:'flex',alignItems:'center',}}>
							<IconButton   style={{marginLeft:isSmall ? isMedium ? 10 : 8 : 6,marginRight: isSmall ? isMedium ? 10 : 8 : 6}} onClick={e => props.router.navigate('/')} aria-label="delete" size="large">
								 <ArrowBackIcon/>
							 </IconButton>

								<img src={`/images/${props.router.params.tick}.png`} style={{borderRadius:50,border: `1.5px solid ${theme.palette.divider}`,width:isSmall ? isMedium ? 65 : 55 : 45, height: isSmall ? isMedium ? 65 : 55 : 45}} alt="src" />
								<Typography color={'secondary'}style={{ marginLeft:isSmall ? isMedium ? 24 : 16 : 8, fontSize: isSmall ? isMedium ? 24 : 22 : 20}} >{props.router.params.tick.toUpperCase()}</Typography>
								</div>

								<div style={{display:'flex',flexDirection:'column',textAlign:'right',marginRight: isSmall ? isMedium ? 10 : 10 : 10}}>
								{props.data && props.data.total &&
									<Fragment>

										<Typography color={'textSecondary'} style={{ marginLeft:isSmall ? isMedium ? 24 : 16 : 8, fontSize: isSmall ? isMedium ? 14 : 12 : 10}} >TOTAL TRANSFERS</Typography>
										<Typography color={'textPrimary'}style={{marginBottom: isSmall ? isMedium ? 8 : 6 : 4, marginLeft:isSmall ? isMedium ? 24 : 16 : 8, fontSize: isSmall ? isMedium ? 16 : 14 : 12}} >{parseInt(props.data.total.totalTxs).toLocaleString('en-US')}</Typography>

										<Typography color={'textSecondary'} style={{ marginLeft:isSmall ? isMedium ? 24 : 16 : 8, fontSize: isSmall ? isMedium ? 14 : 12 : 10}} >TOTAL AMOUNT</Typography>
										<Typography color={'textPrimary'}style={{ marginLeft:isSmall ? isMedium ? 24 : 16 : 8, fontSize: isSmall ? isMedium ? 16 : 14 : 12}} >{parseInt(props.data.total.totalAmount).toLocaleString('en-US')}</Typography>

									</Fragment>
								}
								</div>

						</Paper>




					{props.data && data.length > 0 ?
						<ChartWithVolume data={data} dataOrigin={dataOrigin}
														 checked={checked}
														 matches={matches}
														 handleChange1={handleChange1} handleChange2={handleChange2}
														 handleChangeMarket={handleChangeMarket}
														 interval={interval}  handleChangeInterval={handleChangeInterval}
														 mode={mode} handleChangeMode={handleChangeMode}
														 unit={props.devise}
														 satoshi={props.data.satoshi && props.data.satoshi.price}
														 bitcoin={props.data.bitcoin && props.data.bitcoin}
														 orders={props.data.orders && props.data.orders}/>
						:
						<Box sx={{ display: 'flex',width: '100%',height:"700px",justifyContent: 'center',alignItems:'center' }}>
				      <CircularProgress variant="indeterminate"
					        disableShrink
					        sx={{
					          animationDuration: '550ms',
					        }}
					        size={60}
									color={'secondary'}
					        thickness={5}/>
				    </Box>
					}



  			</div>
}

const Src = withRouter(SrcComponent);
export default Src;
