import React, { Fragment } from 'react';

import IconButton from '@mui/material/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import Brightness3Icon from '@mui/icons-material/Brightness3';


const ThemeSwitch = (props) => {
    //
    const toggleTheme = (e,dark) => {
      e.preventDefault();
      props.setDark(!props.dark)
      localStorage.setItem('dark', !props.dark);
      document.body.style.background = !props.dark ? '#252525' : '#fafafa'
    }


    //
    return (
            <IconButton color={'secondary'} style={{marginRight: props.isSmall ? props.isMedium ? 12 : 8 : 4}}
                        onClick={e => toggleTheme(e)}>
              {props.dark ? <Brightness3Icon /> : <LightModeIcon />}
            </IconButton>
    )
}
export default ThemeSwitch;
