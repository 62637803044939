import React, {useEffect} from 'react';
import { compose } from 'recompose';
//
import { graphql, withApollo, ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import {Client} from '../client/Client'
//
import  Src  from './Src';
//


export const SERVER_QUERY = gql`
  query txsQuery($first: Int!, $skip: Int!, $name: String!, $interval: String!) {
    satoshi {
      price
    }
    orders(name: $name) {
      holdersCount
      openCount
      scanCount
      totalAmount
    }
    txs(first: $first, skip: $skip, name: $name) {
      _id
      type
      date
      amount
      value
    }
    bitcoin(interval : $interval){
      date
      price
    }
    total(name: $name){
      totalTxs
      tTopen
      tTscan
      totalAmount
      tAopen
      tAscan
    }
  }
`

const data = graphql(SERVER_QUERY, {
  options: ownProps => {
    //console.log('ownProps',ownProps);
    return ({
      variables: {
        first: 5000,
        skip:0,
        name: ownProps.router.location.pathname.substring(1),
        interval: '1H'
      }
    })
  },
  client: Client
})


/******************************************************************************/
const Container = compose(
  data,
  withApollo,
)(Src);

export default function SrcContainer(props) {

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: props.router.location.pathname + props.router.location.search,
    });
  }, []);

  return (
    <ApolloProvider client={Client}>
      <Container {...props}/>
    </ApolloProvider>
  );
}

/******************************************************************************/
