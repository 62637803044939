import * as React from 'react';
import * as d3 from "d3";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function TableTxs({data, unit}) {
  var sort = data.sort(function (a, b) {
    return b.date - a.date;
  });

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.up('xs'));

  return (
    <TableContainer component={Paper}  elevation={0} >
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{padding:0}}>Market</TableCell>
            <TableCell style={{padding:0}}>Date</TableCell>
            <TableCell style={{padding:0}}>Price</TableCell>
            <TableCell style={{padding:0}}>Amount</TableCell>
            <TableCell style={{padding:0}} align="left">Total</TableCell>
            <TableCell style={{padding:0}} align="center">Tx</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sort.map((row,i) => {
            var format = d3.timeFormat("%Y-%m-%d %H:%M:%S");
            var date = format(row.date)

            return (
            <TableRow
              key={i}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                } ,
                'td': {
                  fontSize: isSmall ? isMedium ? 15 : 13 : 10,
                } }}

            >
              <TableCell component="th" scope="row" style={{padding:4}}>
                <img src={`/images/${row.type }${theme.palette.mode === 'dark' ? '_light.png' : '.png'}`} style={{width: isSmall ? isMedium ? 40 : 35 : 30,paddingTop:4}} alt="Red dot" />
              </TableCell>
              <TableCell style={{padding:0}}>{date}</TableCell>
              {unit ?
                <TableCell  align="left" style={{padding:0}}>
                  {row.value.toLocaleString('en-US')} sat</TableCell>
                :
                <TableCell  style={{padding:0}}>
                  <AttachMoneyIcon style={{fontSize: isSmall ? isMedium ? 16 : 14 : 12, position:'relative', top: isSmall ? 2.5 : 1.5}}/>{row.price.toLocaleString('en-US')}</TableCell>
              }

              <TableCell style={{padding:0}}>{row.amount}</TableCell>
              {unit ?
                <TableCell align="left" style={{padding:0}}>
                  <CurrencyBitcoinIcon style={{fontSize: isSmall ? isMedium ? 16 : 14 : 12, position:'relative', top: isSmall ? 2.5 : 1.5}}/>{row.btcTotal}</TableCell>
                :
                <TableCell align="left" style={{padding:0}}>
                  <AttachMoneyIcon style={{fontSize: isSmall ? isMedium ? 16 : 14 : 12, position:'relative', top: isSmall ? 2.5 : 1.5}}/>{parseInt(row.priceTotal).toLocaleString('en-US')}</TableCell>
              }
              <TableCell align="center" style={{padding:0,cursor:'pointer'}} onClick={() => window.open('https://mempool.space/tx/'+row._id, '_blank', 'noopener,noreferrer')}>
                  <ArrowForwardIosIcon style={{fontSize:isSmall ? isMedium ? 16 : 14 : 12, paddingTop: 4}}/></TableCell>
            </TableRow>
          )
        }
      )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
