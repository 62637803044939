import React, { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import StorefrontIcon from '@mui/icons-material/Storefront';
import imageBase from '../style/imageBase'

const options = ['openstamp', 'stampscan'];

export default function Exchanges(props) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    props.handleChangeMarket(0);
    setSelectedIndex(0);
    setOpen(false);
  };

  const handleMenuItemClick = (event, index) => {
    props.handleChangeMarket(index+1);
    setSelectedIndex(index+1);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const styleWidthHeight = {
      width:props.isSmall ? props.isMedium ? 90 : 75 : 40,
      height:props.isSmall ? props.isMedium ? 50 : 50 : 48
  }
  return (
    <React.Fragment>
      <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" style={{marginLeft:16,marginRight: props.matches ? 0 : props.isSmall ? props.isMedium ? 12 : 12 : 10}}>
        <Button onClick={handleClick} style={{textTransform:'none',...styleWidthHeight}}> {props.isSmall ? 'Markets': <StorefrontIcon />}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          style={{width:50,paddingLeft: selectedIndex > 0 ? 0 :'auto',paddingRight: selectedIndex > 0 ? 0 :'auto'}}
        >
          <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>
            {selectedIndex > 0 ?
              <img src={`/images/${selectedIndex === 1 ? 'openstamp' : 'stampscan' }${theme.palette.mode === 'dark' ? '_light.png' : '.png'}`} style={{width: props.isSmall ? props.isMedium ? 35 : 30 : 30, padding:4}} alt={selectedIndex} />
            :
              <Fragment>{selectedIndex === 0 && <ArrowDropDownIcon />} </Fragment>
            }
          </div>
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 999,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper style={{backdropFilter: 'blur(6px)'}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem style={{padding:0}}>
                  {options.map((option, index) => (
                    <MenuItem
                      style={{paddingLeft:0, }}
                      key={option}
                      selected={index+1 === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <img src={`/images/${option}${theme.palette.mode === 'dark' ? '_light.png' : '.png'}`} style={{width: props.isSmall ? props.isMedium ? 45 : 40 : 35, padding:4}} alt={option} />
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
