import { parse } from 'svg-parser';
import * as d3 from "d3";
const math = require('mathjs')
const async = require('async');


function convertToOHLC(data) {

    var result = [];
    var format = d3.timeFormat("%Y-%m-%d");
    data.forEach(d => d.time = format(new Date(d.date).getTime()));
    var allDates = [...new Set(data.map(d => d.time))];
    //
    allDates.forEach(d => {
        var tempObject = {};
        var filteredData = data.filter(e => e.time === d);
        //
        tempObject._id = filteredData[0]._id;
        tempObject.time = d;
        tempObject.date = filteredData[0].date;
        tempObject.type = filteredData[0].type;
        tempObject.value = filteredData[0].value;
        tempObject.amount = filteredData[0].amount;
        tempObject.open = filteredData[0].value;
        tempObject.close = filteredData[filteredData.length - 1].value;
        tempObject.high = d3.max(filteredData, e => e.value);
        tempObject.low = d3.min(filteredData, e => e.value);
        //
        //add other samedate
        if(filteredData.length > 0){
          for (var i = 1; i < filteredData.length; i++) {
            filteredData[i].open = filteredData[0].value;
            filteredData[i].close = filteredData[filteredData.length - 1].value;
            filteredData[i].high = d3.max(filteredData, e => e.value);
            filteredData[i].low = d3.min(filteredData, e => e.value);
            result.push(filteredData[i]);
          }
        }
        //
        result.push(tempObject);
    });
    return result;
};
function convertToOHLC_4H(data) {
  var result = [];
    var format = d3.timeFormat("%Y-%m-%d %H:%M:%S");

    // Extract time from the date with 4-hour intervals
    data.forEach(d => {
        var date = new Date(d.date);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date.setHours(Math.floor(date.getHours() / 4) * 4); // Round down to the nearest 4-hour interval
        d.time = format(date);
    });

    var allDates = [...new Set(data.map(d => d.time))];

    allDates.forEach(d => {
        var tempObject = {};
        var filteredData = data.filter(e => e.time === d);
        if (filteredData.length > 0) {
            tempObject._id = filteredData[0]._id;
            tempObject.time = d;
            tempObject.date = filteredData[0].date;
            tempObject.type = filteredData[0].type;
            tempObject.value = filteredData[0].value;
            tempObject.amount = filteredData[0].amount;
            tempObject.open = filteredData[0].value;
            tempObject.close = filteredData[filteredData.length - 1].value;
            tempObject.high = d3.max(filteredData, e => e.value);
            tempObject.low = d3.min(filteredData, e => e.value);

            // Add other data with the same date
            for (var i = 1; i < filteredData.length; i++) {
                filteredData[i].open = filteredData[0].value;
                filteredData[i].close = filteredData[filteredData.length - 1].value;
                filteredData[i].high = d3.max(filteredData, e => e.value);
                filteredData[i].low = d3.min(filteredData, e => e.value);
                result.push(filteredData[i]);
            }
            result.push(tempObject);
        }
    });
    result.sort(function (a, b) {
      return a.date - b.date;
    });
    return result;
}
function convertToOHLC_1H(data) {
  var result = [];
    var format = d3.timeFormat("%Y-%m-%d %H:%M:%S");

    // Extract time from the date with 4-hour intervals
    data.forEach(d => {
        var date = new Date(d.date);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date.setHours(date.getHours() + 1 ); // Round down to the nearest 4-hour interval
        d.time = format(date);
    });

    var allDates = [...new Set(data.map(d => d.time))];

    allDates.forEach(d => {
        var tempObject = {};
        var filteredData = data.filter(e => e.time === d);
        if (filteredData.length > 0) {
            tempObject._id = filteredData[0]._id;
            tempObject.time = d;
            tempObject.date = filteredData[0].date;
            tempObject.type = filteredData[0].type;
            tempObject.value = filteredData[0].value;
            tempObject.amount = filteredData[0].amount;
            tempObject.open = filteredData[0].value;
            tempObject.close = filteredData[filteredData.length - 1].value;
            tempObject.high = d3.max(filteredData, e => e.value);
            tempObject.low = d3.min(filteredData, e => e.value);

            // Add other data with the same date
            for (var i = 1; i < filteredData.length; i++) {
                filteredData[i].open = filteredData[0].value;
                filteredData[i].close = filteredData[filteredData.length - 1].value;
                filteredData[i].high = d3.max(filteredData, e => e.value);
                filteredData[i].low = d3.min(filteredData, e => e.value);
                result.push(filteredData[i]);
            }
            result.push(tempObject);
        }
    });
    result.sort(function (a, b) {
      return a.date - b.date;
    });
    return result;
}

function findTxs(data) {
    //data.sort((a, b) => d3.ascending(a.date, b.date));
    var result = [];
    var format = d3.timeFormat("%Y-%m-%d");
    data.forEach(d => d.time = format(new Date(d.date).getTime()));
    var allDates = [...new Set(data.map(d => d.time))];
    //
    allDates.forEach(d => {
        var tempObject = {};
        var filteredData = data.filter(e => e.time === d);
        //
        result.push(filteredData);
    });
    return result;
};


export default {
    //
    cleanData:async function(data, settingClean){



      const cleanedData = [];

      for (let i = 0; i < data.length; i++) {
        // Ignorer les cinq premières transactions
        if (i < 5) {
          cleanedData.push(data[i]);
          continue;
        }

        // Calculer les variations par rapport aux cinq transactions précédentes
        const variations = [];
        for (let j = 1; j <= 5; j++) {
          variations.push((data[i].value - data[i - j].value) / data[i - j].value);
        }

        // Vérifier si la variation dépasse 40% ou est inférieure à -40%
        if (variations.every(variation => Math.abs(variation) <= 0.4)) {
          cleanedData.push(data[i]);
        }
      }



      var t = cleanedData.filter(function(x) {
           return x !== undefined;
      });
      t.sort(function (a, b) {
        return a.date - b.date;
      });


      return t
    },
    createData: async function(data, interval){
      //
      var ohlc = []
      if(interval == '1D'){
        //console.log('1D =>');
        ohlc = convertToOHLC(data);
      }
      if(interval == '4H'){
        ohlc = convertToOHLC_4H(data);
      }
      if(interval == '1H'){
        ohlc = convertToOHLC_1H(data);
      }
      // Group data by date and calculate aggregates + calculate average
      const resultArray = []
      const timestampToDate = (timestamp) => {

        const date = new Date(timestamp) //* 1000);
        // Adjust for your timezone if needed
        if(interval == '1D'){
          var format = d3.timeFormat("%Y-%m-%d");
          return format(date)
          //return date.toISOString().split('T')[0];
        } else if(interval == '4H'){
          //console.log('timestamp',date.toISOString().split('T'));
          var format = d3.timeFormat("%Y-%m-%d %H:%M:%S");
          return format(date)
        } else if(interval == '1H'){
          //console.log('timestamp',date.toISOString().split('T'));
          var format = d3.timeFormat("%Y-%m-%d %H:%M:%S");
          return format(date)
        }
        //var format = d3.timeFormat(frt);
        //return format(date)
      };
      const aggregatedData = ohlc.reduce((result, entry) => {

        const date = timestampToDate(entry.time);
        //
        if (!result[date]) {
          result[date] = {
            totalValue: 0,
            totalAmount: 0,
            count: 0,
          };
        }
        result[date].totalValue += entry.value;
        result[date].totalAmount += entry.amount;
        result[date].count += 1;
        //
        result[date].type = entry.type
        result[date].open = entry.open
        result[date].close = entry.close
        result[date].high = entry.high
        result[date].low = entry.low

        return result;
      }, {});

      Object.entries(aggregatedData).forEach(([date, values], index, array) => {

        resultArray.push({
          time:date,
          averageValue: values.totalValue / values.count,
          totalAmount: values.totalAmount,
          count: values.count,
          //
          type:values.type,
          open:values.open,
          close:values.close,
          high:values.high,
          low:values.low
        });
      });

      //
      function addRowsForDays(data) {
        const newData = [];

        for (let i = 0; i < data.length - 1; i++) {
          const currentDate = new Date(data[i].time);
          const nextDate = new Date(data[i + 1].time);

          while (currentDate < nextDate) {

            var format = d3.timeFormat("%Y-%m-%d");
            const currentDateStr = format(currentDate)

            // Check if the date already exists in newData
            const dateExists = data.some(entry => entry.time === currentDateStr);
            if (!dateExists) {
              newData.push({ ...data[i], time: currentDateStr, count: 0 });
            } else {
              newData.push({ ...data[i]});
            }

            currentDate.setDate(currentDate.getDate() + 1);
          }
        }

        // Add the last date
        const lastIndex = data.length - 1;
        const lastDateStr = new Date(data[lastIndex].time).toISOString().split('T')[0];
        // Check if the last date already exists in newData
        const lastDateExists = newData.some(entry => entry.time === lastDateStr);

        if (!lastDateExists) {
          newData.push({ ...data[lastIndex]});
        } else {
          newData.push({ ...data[lastIndex]});
        }


        return newData;
      }
      function addRowsFor4Hours(data) {
        const newData = [];

        for (let i = 0; i < data.length - 1; i++) {
          const currentDate = new Date(data[i].time);
          const nextDate = new Date(data[i + 1].time);

          while (currentDate < nextDate) {

            var format = d3.timeFormat("%Y-%m-%d %H:%M:%S");
            const currentDateStr = format(currentDate)

            // Check if the date already exists in newData
            const dateExists = data.some(entry => entry.time === currentDateStr);

            if (!dateExists) {
              newData.push({ ...data[i], time: currentDateStr, count: 0 });
            } else {
              newData.push({ ...data[i] });
            }

            currentDate.setHours(currentDate.getHours() + 4); // Add 4 hours
          }
        }

        // Add the last date
        const lastIndex = data.length - 1;
        const lastDateStr = new Date(data[lastIndex].time).toISOString().split('T')[0];
        // Check if the last date already exists in newData
        const lastDateExists = newData.some(entry => entry.time === lastDateStr);

        if (!lastDateExists) {
          newData.push({ ...data[lastIndex]});
        } else {
          newData.push({ ...data[lastIndex] });
        }

        return newData;
      }
      function addRowsFor1Hours(data) {
        const newData = [];

        for (let i = 0; i < data.length - 1; i++) {
          const currentDate = new Date(data[i].time);
          const nextDate = new Date(data[i + 1].time);

          while (currentDate < nextDate) {

            var format = d3.timeFormat("%Y-%m-%d %H:%M:%S");
            const currentDateStr = format(currentDate)

            // Check if the date already exists in newData
            const dateExists = data.some(entry => entry.time === currentDateStr);

            if (!dateExists) {
              newData.push({ ...data[i], time: currentDateStr, count: 0 });
            } else {
              newData.push({ ...data[i] });
            }

            currentDate.setHours(currentDate.getHours() + 1); // Add 4 hours
          }
        }

        // Add the last date
        const lastIndex = data.length - 1;
        const lastDateStr = new Date(data[lastIndex].time).toISOString().split('T')[0];
        // Check if the last date already exists in newData
        const lastDateExists = newData.some(entry => entry.time === lastDateStr);

        if (!lastDateExists) {
          newData.push({ ...data[lastIndex]});
        } else {
          newData.push({ ...data[lastIndex] });
        }

        return newData;
      }
      //console.log('resultArray',resultArray);
      var newData = []
      if(interval == '1D'){
        newData = addRowsForDays(resultArray);
      } else if(interval == '4H') {
        newData = addRowsFor4Hours(resultArray)
      } else if(interval == '1H') {
        newData = addRowsFor1Hours(resultArray)
      }
      var dataArray = this.addLastGhostData(newData, interval)
      //
      return [dataArray, ohlc];

    },
    addLastGhostData: function(dataArray, interval){
      //
      var lastDate = new Date(dataArray[dataArray.length - 1].time);
      if(interval === '4H'){
        var setDatePlus = lastDate.setHours(lastDate.getHours() + 4);
      } else if(interval === '1D'){
        var setDatePlus = lastDate.setHours(lastDate.getHours() + 24);
      } else if(interval === '1H'){
        var setDatePlus = lastDate.setHours(lastDate.getHours() + 1);
      }
      var lastDatePlus = new Date(setDatePlus);


      const currentDate = new Date();
      //Verify last data is not in last 4H or 1D
      if(lastDatePlus.getTime() < currentDate.getTime()){
        var i = 0;
        while (lastDate.getTime() < currentDate.getTime()) {
          if(interval === '1D'){
            lastDate.setDate(lastDate.getDate() + i);
          } else if(interval === '4H'){
            lastDate.setHours(lastDate.getHours() + (i * 4));
          } else if(interval === '1H'){
            lastDate.setHours(lastDate.getHours() + (i * 1));
          }


          if(dataArray.length > 0 ){
            var value = dataArray[dataArray.length - 1].averageValue;
          } else {
            var value = 0
          }
          var formatDate = ''
          if(interval === '1D'){
            formatDate = lastDate.toISOString().slice(0, 19).split('T')[0]
          } else if(interval === '4H' || interval === '1H'){
            formatDate = lastDate.toISOString().slice(0, 19).replace('T', ' ')
          }
          //
          const newRow = {
            date: lastDate.getTime(),
            averageValue: value,
            totalAmount: 0,
            count: 0,
            open: 0,
            close: 0,
            high: 0,
            low: 0,
            time: formatDate
          };

          dataArray.push(newRow);
          i = 1;
        }
      }
      return dataArray;
    },
}
