import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'recompose';
import { withStyles } from '@mui/styles';
import Lib from '../Lib'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChartWithVolume from './ChartWithVolume'

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {withRouter} from '../withRouter'
import imageBase from '../style/imageBase'
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import TableStatsHead from './TableStatsHead'




import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Skeleton from '@mui/material/Skeleton';
import StarIcon from '@mui/icons-material/Star';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { ChartContainer } from '@mui/x-charts';
import { LinePlot} from '@mui/x-charts/LineChart';
import CircularProgress from '@mui/material/CircularProgress';


const styles = theme => ({
    content:{
      //
    },
});

//const HomeComponent = (props) => {
export class HomeComponent extends React.Component {
	  constructor (props) {
	    super(props);

	    this.state = {
	      waitScroll: false,
	      loadingBottom: false,
	      noMore: false,
	    };
	    this.scrollTo = this.scrollTo.bind(this);
	  }
	  componentDidMount() {
	    //ReactDOM.findDOMNode(this.nv).addEventListener('nv-scroll', this.scrollTo);
	    setTimeout(() => {
	      this.scrollTo();
	      this.setState({waitScroll:true})
	    }, 100)
	  }
	  componentWillUnmount() {
	    //ReactDOM.findDOMNode(this.nv).removeEventListener("nv-scroll", this.scrollTo);
	  }
	  //
	  handleScroll(event){

	    var node = event.target;

	    //this.props.clientStorage.set('anchor_home',node.scrollTop);
	    //
	    if(node.scrollTop == 0){
	      //Top
	      //console.log(node.scrollTop);
	      //this.props.clientStorage.set('anchor_status','top');

	    } else if ((node.scrollHeight - node.scrollTop)-0.5 < node.clientHeight ) {

	      //this.props.clientStorage.set('anchor_status','bottom');
	      if(this.state.loadingBottom == false ){
	          console.log('loadMoreCaps ');
            this.setState({loadingBottom:true})
            //
	          setTimeout(() => {
	            this.props.loadMoreCaps().then(res => {
	              if(res.loading == false){
	                this.setState({loadingBottom:false})
	                //this.props.clientStorage.set('anchor_status','null');
	                if(res.data.caps.length == 0 ||  res.data.caps.length < 10){
	                  console.log('noMore Caps');
	                  this.setState({noMore:true})
	                }
	              }
	            });
	          }, 500)

	      }

	    } else {
	      //this.props.clientStorage.set('anchor_status','null');
	    }

	  }
	  scrollTo = () => {
	    //this.nv.scrollTop = this.props.clientStorage.get('anchor_home');
	  }
	  //
	  render() {


		const { data : { loading, error, caps, variables }, classes, history, propsWidth } = this.props;

    var display = {
      display: propsWidth.isMedium ? 'table-cell' : 'none'
    }

		var hidenMobile = {
			display:  propsWidth.isSmall ? 'table-cell' : 'none'
		}
    //var loading = true
		return (
					<TableContainer   style={{
							position:  propsWidth.matches ? 'relative' : 'absolute',
							left:0,
							top: propsWidth.matches ? 0 : propsWidth.isSmall ? propsWidth.isMedium ? 120 : 80 : 60,
							maxWidth: 1200,
							width: propsWidth.matches ? 1200 : '100%',
						}}>

						<Table  aria-label="simple table">
							<TableStatsHead {...this.props} />
						</Table>

						<div id={'caps'}
		              ref={elem => this.nv = elem}
		              className={`${classes.containerColor} container scrollbar-hidden`}
                  onScroll={e => this.handleScroll(e)} style={{overflow: 'scroll', height: 'calc(100vh - 120px)'}}>

		            <Fade in={this.state.waitScroll} timeout={500}>
		              <div className="grid-row" style={{paddingBottom:145}}>

		                  {caps && caps.map((collection, i) => {

												const filterNumbers = (arr) => {
													return arr.filter((num, index) => {
														// Vérifie si l'élément précédent existe et si la condition est satisfaite
														if (index > 0 && (num < arr[index - 1] * 0.2 || num > arr[index - 1] * 2) ) {
															return false; // Ne pas inclure le nombre dans le résultat
														}
														return true; // Inclure le nombre dans le résultat
													});
												};

												var series = collection.data7.map(x => x.value)
												var seriesUSD = collection.data7_USD.map(x => x.value)
												const series1 = filterNumbers(series);
												const series1USD = filterNumbers(seriesUSD);
												var xAxis = series1.map((x, i) => i)
												var xAxisUSD = series1USD.map((x, i) => i)

												//
												var color = "";
												var start = series1[0]
												var end = series1[series1.length -1]
												if(start < end){
													color = 'rgb(120,188,141)';
												} else if(start > end) {
													color = 'rgb(200,83,79)';
												}  else if(start === end) {
													//color = theme.palette.text.secondary;
												}
												var colorUSD = "";
												var start = series1USD[0]
												var end = series1USD[series1USD.length -1]
												if(start < end){
													colorUSD = 'rgb(120,188,141)';
												} else if(start > end) {
													colorUSD = 'rgb(200,83,79)';
												}  else if(start === end) {
													//colorUSD = theme.palette.text.secondary;
												}

											 return (
		                    <div
                          onClick={e => collection.price === 0 ?  console.log('no trade') : this.props.router.navigate(collection.name)}
		                      className="grid-item"
		                      style={{ flexDirection:'column'}}
		                      key={collection.name}>

		                              <Paper item style={{ borderRadius: 15 }} className="grid-item-container"  elevation={2}>


																					<div style={{display:'flex', width:'100%', alignItems:'center', justifyContent: 'space-between', flexDirection:'row'}}>

    																					<Typography variant="body1" color="secondary" style={{marginLeft:  propsWidth.isMedium ? 12 : 6,marginRight: propsWidth.isMedium ? 12 : 6, width: 16,  textAlign: 'center'}}>
    																						{loading ? <Skeleton style={{margin:0, width:20}}/> : i + 1 }
    																					</Typography>


		                                          {loading ?
		                                            <Skeleton className={classes.logo} variant="circle" width={50} height={50} />
				                                          :
                                                  <img src={`/images/${collection.name}.png`} style={{
                                                    borderRadius:50,
                                                    border: `1.5px solid ${propsWidth.theme.palette.divider}`,
                                                    width: propsWidth.matches ? 55 : propsWidth.isSmall ? 45 : 35,
                                                    marginTop: propsWidth.isSmall ? 0 : 8,
                                                    marginBottom: propsWidth.isSmall ? 0 : 8}} alt="src" />

				                                      }

				                                      <Typography variant="body1" style={{width:60, marginLeft: 6}}>
				                                        {loading ? <Skeleton style={{margin:0, width:60}}/> : collection.name }
				                                      </Typography>






																							<div style={{display:'flex',justifyContent: 'center',textAlign: 'right', width:80, paddingRight:8}}>
																								{this.props.devise ?
				                                            <Typography variant="body2" color='primary'>
				                                              {loading ? <Skeleton style={{width:80}}/> : collection.value }
				                                            </Typography>
																										:
																										<Typography variant="body2" color='primary'>
				                                              {loading ? <Skeleton style={{width:80}}/> : <span><AttachMoneyIcon style={{fontSize: 15, position:'relative', top: 1.5}}/>{collection.price.toFixed(4)}</span>}
				                                            </Typography>
																									}
																							</div>


																							<div style={{display:'flex',justifyContent: 'space-between', width:  propsWidth.isMedium ? 180 : 'auto'}}>
                                              {loading ? <Skeleton style={{margin:0, width:30}}/> : <Fragment>
                                                {this.props.devise ?
																									<Typography  variant="body2" style={{...display, color: collection.percentMove_4H > 0 ? 'rgb(120,188,141)' : collection.percentMove_4H === 0 ? '': 'rgb(200,83,79)'}}>{collection.percentMove_4H}%</Typography>
																									:
																									<Typography  variant="body2" style={{...display, color: collection.percentMove_4H_USD > 0 ? 'rgb(120,188,141)' : collection.percentMove_4H_USD === 0 ? '' : 'rgb(200,83,79)'}}>{collection.percentMove_4H_USD}%</Typography>
																								}
                                                </Fragment>}
                                                {loading ? <Skeleton style={{margin:0, width:30}}/> : <Fragment>
  																								{this.props.devise ?
  																									<Typography  variant="body2" style={{color: collection.percentMove_1D > 0 ? 'rgb(120,188,141)' : collection.percentMove_1D === 0 ? '': 'rgb(200,83,79)'}}>{collection.percentMove_1D}%</Typography>
  																									:
  																									<Typography  variant="body2" style={{color: collection.percentMove_1D_USD > 0 ? 'rgb(120,188,141)' : collection.percentMove_1D_USD === 0 ? '' : 'rgb(200,83,79)'}}>{collection.percentMove_1D_USD}%</Typography>
  																								}
                                                </Fragment>}
                                                {loading ? <Skeleton style={{margin:0, width:30}}/> : <Fragment>
  																								{this.props.devise ?
  																									<Typography  variant="body2" style={{...display, color: collection.percentMove_7D > 0 ? 'rgb(120,188,141)' : collection.percentMove_7D === 0 ? '': 'rgb(200,83,79)'}}>{collection.percentMove_7D}%</Typography>
  																									:
  																									<Typography  variant="body2" style={{...display, color: collection.percentMove_7D_USD > 0 ? 'rgb(120,188,141)' : collection.percentMove_7D_USD === 0 ? '' : 'rgb(200,83,79)'}}>{collection.percentMove_7D_USD}%</Typography>
  																								}
                                                </Fragment>}
																							</div>


																							<div style={{marginLeft: 12, width:130, textAlign: 'right'}}>
																							  {this.props.devise ?
																									<Typography variant="body1" color='primary' style={{ paddingRight: propsWidth.isMedium ? 4 : 16}}>
																										{loading ? <Skeleton style={{width:120}}/> : collection.marketcap.toLocaleString('en-US') }
																									</Typography>
																									:
																									<Typography variant="body1" color='primary' style={{ paddingRight: propsWidth.isMedium ? 4 : 16}}>
																										{loading ? <Skeleton style={{width:120}}/> : <span><AttachMoneyIcon style={{fontSize: 15, position:'relative', top: 1.5}}/>{parseInt(collection.marketcap_USD).toLocaleString('en-US')}</span>}
																									</Typography>
																								}
																							</div>

																							<div style={{marginLeft: 12, width:120, textAlign: 'right', ...display}}>
  																							{this.props.devise ?
  																									<Typography variant="body1" color='primary'>
  																										{loading ? <Skeleton style={{width:120}}/> : collection.totalVolume1D.toLocaleString('en-US') }
  																									</Typography>
  																									:
  																									<Typography variant="body1" color='primary'>
  																										{loading ? <Skeleton style={{width:120}}/> : <span><AttachMoneyIcon style={{fontSize: 15, position:'relative', top: 1.5}}/>{collection.totalVolume1D_USD.toLocaleString('en-US')}</span>}
  																									</Typography>
  																								}
																							</div>

																							<div style={{marginLeft: 12, width:130, textAlign: 'right', ...display}}>
																								<Typography variant="body1" color='primary'>
																									{loading ? <Skeleton style={{width:120}}/> : <span>{parseInt(collection.totalSupply).toLocaleString('en-US')}</span>}
																								</Typography>
																							</div>


												                        <div  align="right" style={{paddingRight:16, paddingLeft:8,paddingTop: 0,paddingBottom:0,...hidenMobile}}>
												                         {loading ? <Skeleton style={{margin:0, width:140, height: 80}}/> : <Fragment> {series1.length > 0 &&
												                              <ChartContainer
												                                width={140}
												                                height={80}
												                                margin={{ top: 10, bottom: 10 ,left:5, right:8}}
												                                series={[{ type: 'line', data: this.props.devise ? series1 :  series1USD }]}
												                                xAxis={[{ scaleType: 'point', data: xAxis }]}
												                                sx={{
												                                  '.MuiLineElement-root': {
												                                    stroke: color,
												                                    strokeWidth: 3,
												                                  },
												                                  '.MuiMarkElement-root': {
												                                    display:'none',
												                                    stroke: '#8884d8',
												                                    scale: '0.6',
												                                    fill: '#fff',
												                                    strokeWidth: 0,
												                                  },
												                                }}
												                                disableAxisListener
												                              >
												                                <LinePlot />

												                              </ChartContainer>
												                            }
                                                    </Fragment>}
												                        </div>


                                            </div>




		                          </Paper>

		                  </div>
		                )
									}
									)
								}


		          </div>
		        </Fade>
            {this.state.loadingBottom && !this.state.noMore &&
              <div style={{width:'100%', display: 'flex', justifyContent: 'center'}}>
                <CircularProgress size={40} style={{position:'absolute',bottom:80, margin:0}} color="secondary" />
              </div>
            }
					</div>

				</TableContainer>
		)
	}
};

const withMediaQuery = () => Component => props => {
    //
    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
    const isMobile = useMediaQuery(theme.breakpoints.up('xs'));

  return <Component propsWidth={{theme: theme, matches: matches, isMedium: isMedium, isSmall: isSmall, isMobile: isMobile }} {...props} />;
}
const HomeWrapper = compose(withStyles(styles),withMediaQuery())(HomeComponent);
const Home = withRouter(HomeWrapper);

export default Home;
