import React, {Fragment, useEffect, useState} from 'react';
import {withRouter} from './withRouter'
//
import { Route, Routes } from 'react-router-dom';
import HomeContainer from './ui/HomeContainer';
import SrcContainer from './ui/SrcContainer';

import { MuiThemeProvider, createMuiTheme } from '@mui/styles';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ThemeSwitch from './ThemeSwitch'
import DeviseSwitch from './DeviseSwitch'

import Container from '@mui/material/Container';

const themeLight = createTheme({
   palette:{
     mode: 'light',
     background: '#fafafa',
     primary:{
       main: "#111"
     },
     secondary:{
       main: "#f2a900"
     }
   },
   typography: {
    "fontFamily": `'Roboto', "Helvetica", "Arial", sans-serif`,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  breakpoints: {
     values: {
       xs: 0,
       sm: 590,
       md: 900,
       lg: 1200,
       xl: 1920,
     },
   },
   overrides:{

   }
});
const themeDark = createTheme({
  palette:{
    mode: 'dark',
    background: '#202020',
    primary:{
      main: "#fafafa"
    },
    secondary:{
      main: "#B76E79"
    }
  },
   typography: {
    "fontFamily": `'Roboto', "Helvetica", "Arial", sans-serif`,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  breakpoints: {
     values: {
       xs: 0,
       sm: 590,
       md: 900,
       lg: 1200,
       xl: 1920,
     },
   },
   overrides:{

   }
});

function AppComponent(props) {
  //
  const [dark, setDark] = useState(false);
  const [devise, setDevise] = useState(false);

  const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
  const setToggleTheme = (fx) => { fx(); }
  //
  //console.log('localStorage',localStorage);
  var test = JSON.parse(localStorage.getItem('dark'))
  var themeApp = null
  if(test !== null){

    themeApp = test ? themeDark : themeLight

  } else {

    var isDarkDefault = isBrowserDefaultDark()
    themeApp = isDarkDefault ? themeDark : themeLight
  }

  useEffect(() => {
    //
    var test = JSON.parse(localStorage.getItem('dark'))
    if(test !== null){
      if(test){
        setDark(test)
        localStorage.setItem("dark", test);
        document.body.style.background = '#202020'
      } else {
        setDark(false)
        localStorage.setItem("dark", false);
        document.body.style.background = '#fafafa'
      }

    } else {
      var isDarkDefault = isBrowserDefaultDark()
      setDark(isDarkDefault)
      localStorage.setItem("dark", isDarkDefault);
      document.body.style.background = isDarkDefault ? '#202020' : '#fafafa'
    }

  }, [])

  const matches = useMediaQuery(themeApp.breakpoints.up('lg'));
  const isMedium = useMediaQuery(themeApp.breakpoints.up('md'));
  const isSmall = useMediaQuery(themeApp.breakpoints.up('sm'));
  console.log(themeApp);
  return (
    <ThemeProvider theme={themeApp}>

          <div
            style={{ display: 'flex',flexDirection: 'column',  alignItems: 'center', }}
          >

            <div style={{display:'flex',  justifyContent: 'space-between',width: matches ? 1200 : '100%', alignItems:'center',height: isSmall ? isMedium ? 120 : 80 : 60}}>
  						<div style={{display:'flex', alignItems:'center', marginLeft:isSmall ? isMedium ? 12 : 8 : 6}}>
  							<IconButton style={{padding:0}} onClick={ e => props.router.navigate('/')}><img src={`/${dark ? 'logoLight' : 'logo'}.png`} style={{width: isSmall ? isMedium ? 55 : 50 : 40, height:isSmall ? isMedium ? 55 : 50 : 40}} alt="logo" /></IconButton>
  							<Typography color={'textSecondary'} style={{ fontSize:isSmall ? isMedium ? 24 : 21 : 18,marginLeft:isSmall ? isMedium ? 12 : 8 : 6}} >StampMarketCap</Typography>
  						</div>
              <div style={{display:'flex', alignItems:'center'}}>
                <DeviseSwitch devise={devise} setDevise={setDevise} isSmall={isSmall} isMedium={isMedium}/>
    						{isSmall && <Typography color={'secondary'}  style={{fontSize: isSmall ? isMedium ? 24 : 21 : 18, right:0,paddingRight:isSmall ? isMedium ? 24 : 16 : 8}}>
    							SRC-20
    						</Typography>}
                <ThemeSwitch dark={dark} setDark={setDark} isSmall={isSmall} isMedium={isMedium}/>

              </div>
					   </div>
            <Routes>
              <Route name="Home" path="/" element={<HomeContainer {...props} devise={devise} />} />
              <Route name="Src" path="/:tick" element={<SrcContainer {...props} devise={devise}/>} />
            </Routes>
          </div>
      </ThemeProvider>
  );
}
export const App = withRouter(AppComponent);
