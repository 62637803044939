import React, { Fragment } from 'react';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 34,
  height: 18,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#73454c' : '#c48900',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 14,
    height: 14,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const DeviseSwitch = (props) => {
    //
    const toggleDevise = (e,dark) => {
      e.preventDefault();
      props.setDevise(!props.devise)
    }
    //
    return (
            <div style={{display:'flex', alignItems:'center', marginRight: props.isSmall ? props.isMedium ? 30 : 20 : 16}}>
            <AttachMoneyIcon color={'primary'}/>
            <AntSwitch onClick={e => toggleDevise(e)} checked={props.devise} value={props.devise} inputProps={{ 'aria-label': 'ant design' }} />
            <CurrencyBitcoinIcon color={'primary'}/>
          </div>
    )
}
export default DeviseSwitch;
