import React, { Fragment, useState, useEffect, useRef } from 'react';
import * as d3 from "d3";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableTxs from './TableTxs'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import imageBase from '../style/imageBase'
import Candlestick from './Candlestick'

import Paper from '@mui/material/Paper';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';

import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

import Exchanges from './Exchanges'

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));
function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}


export default function ChartWithVolume({data, dataOrigin, interval, mode, checked, matches, handleChange1, handleChange2,handleChangeMarket,handleChangeInterval, handleChangeMode,unit, satoshi, bitcoin,orders}) {
    //
    const [dataPrice, setDataPrice] = useState([]);
    const [dataCandlestick, setDataCandlestick] = useState([]);
    const [dataVolume, setDataVolume] = useState([]);
    const [dataRange, setDataRange] = useState([]);

    //Stat
    const [dataCandle, setDataCandle] = useState({});
    const [dataCandleCount, setDataCandleCount] = useState([]);


    useEffect(() => {

      // Function to format date in 'YYYY-MM-DD' format
      const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toISOString().split('T')[0];
      };
      function roundToNearestDay(date) {
            // Clone the date to avoid modifying the original date object
            const roundedDate = new Date(date);

            // Set hours, minutes, seconds, and milliseconds to 0 to round to the start of the day
            roundedDate.setHours(0, 0, 0, 0);

            return roundedDate;
        }

      var averages = bitcoin.reduce((result, item) => {
        var formattedDate = formatDate(item.date)
        //
        if (!result[formattedDate]) {
          result[formattedDate] = [];
        }
        result[formattedDate] = item.price;
        return result;
      }, {});
      //

      var currentDate = new Date()
      currentDate.setHours(currentDate.getHours() + 1);
      var today = currentDate.toISOString().slice(0, 19).split('T')[0]
      if(!averages[today]){
        averages[today] = satoshi * 100000000
        //console.log('satoshi',satoshi * 100000000);
      }
      //averages = satoshi
      //console.log('averages',averages);
      //
      var datasetLine = []
      data.forEach((item, i) => {
        var value = 0;
        if(!unit){
          if(interval === '1D'){
            var ifDate = averages[item.time];

          } else if(interval === '4H' || interval === '1H') {

            var formatMinuit = formatDate(item.time)

            var ifDate = averages[formatMinuit];
          }
          if(ifDate > 0){
            //
            var btcPriceUSD = ifDate;
            const satoshisPerBitcoin = 1e8;
            const priceForOneSatoshi = (btcPriceUSD / satoshisPerBitcoin).toFixed(10);
            //
            datasetLine.push({
              value: item.averageValue * priceForOneSatoshi,
              time: new Date(item.time).getTime() /1000,//interval === '4H' ? item.time : new Date(item.time).toISOString().split('T')[0],
            })
          } else {
              //console.log('item.time',item.time);
          }
        } else {
          datasetLine.push({
            value: item.averageValue,
            time: new Date(item.time).getTime() /1000,//interval === '4H' ? item.time : new Date(item.time).toISOString().split('T')[0],
          })
        }
      });
      setDataPrice(datasetLine)
      //
      var datasetVol = []
      data.forEach((item, i) => {

          //
          var color = 'rgba(256,256,256, 0)'
          if(item.count > 0){
              if(item.open > item.close){
                color = '#ef5350'
              } else if(item.open < item.close){
                color = '#26a69a'
              } else if(item.open === item.close){
                color = '#26a69a'
              }
          }
          if(!unit){
            if(interval === '1D'){
              var ifDate = averages[item.time];
            } else if(interval === '4H' || interval === '1H') {
              var roundedDate = roundToNearestDay(item.time)
              var findMidnight = new Date(roundedDate).toISOString().split('T')[0];
              var newDate = new Date(findMidnight).setHours(0,0,0,0);
              var formatMinuit = formatDate(newDate)

              var ifDate = averages[formatMinuit];
            }

            if(ifDate > 0){
              //
              var btcPriceUSD = ifDate;
              const satoshisPerBitcoin = 1e8;
              const priceForOneSatoshi = (btcPriceUSD / satoshisPerBitcoin).toFixed(10);
              //
              datasetVol.push({
                value: item.totalAmount * (item.averageValue * priceForOneSatoshi),
                time:  new Date(item.time).getTime() /1000,//interval === '4H' ? item.time : new Date(item.time).toISOString().split('T')[0],
                color: color
              })
            } else {
              //console.log('item.time',item.time);
            }
          } else {
            datasetVol.push({
              value: item.totalAmount,
              time:  new Date(item.time).getTime() /1000,//interval === '4H' ? item.time : new Date(item.time).toISOString().split('T')[0],
              color: color
            })
          }
      });
      setDataVolume(datasetVol)

      //
      var datasetCandle = []
      data.forEach((item, i) => {
        if(!unit){
          if(interval === '1D'){
            var ifDate = averages[item.time];
          } else if(interval === '4H' || interval === '1H') {
            var roundedDate = roundToNearestDay(item.time)
            var findMidnight = new Date(roundedDate).toISOString().split('T')[0];
            var newDate = new Date(findMidnight).setHours(0,0,0,0);
            var formatMinuit = formatDate(newDate)

            var ifDate = averages[formatMinuit];
          }

          if(ifDate > 0){
            //
            var btcPriceUSD = ifDate;
            const satoshisPerBitcoin = 1e8;
            const priceForOneSatoshi = (btcPriceUSD / satoshisPerBitcoin).toFixed(10);
            //
            datasetCandle.push({
              open: item.open * priceForOneSatoshi,
              high: item.high * priceForOneSatoshi,
              low: item.low * priceForOneSatoshi,
              close: item.close * priceForOneSatoshi,
              time: new Date(item.time).getTime() /1000
            })
          } else {
            //console.log('item.time',item.time);
          }
        } else {
          datasetCandle.push({
            open: item.open,
            high: item.high,
            low: item.low,
            close: item.close,
            time: new Date(item.time).getTime() /1000
          })
        }

      });
      setDataCandlestick(datasetCandle)



      return () => {
        // Here goes the code you wish to run on unmount
        //console.log('unmount');
      }
    }, [data,unit]);



    useEffect(() => {

          var routeData = []

          var testRange = []
          testRange = [ data[data.length-1].time ]
          //console.log('data',data);
          //console.log('testRange',testRange);
          setDataRange(testRange)
          //
          var test = []
          testRange.forEach((item, i) => {
            var filteredData = dataOrigin.filter(e => e.time === item);
            test.push(...filteredData)
          });

          routeData = test


        //STAT
        //Percent volume between marketplace
        // Calculate the total amount for each type dynamically
        const totalAmounts = {};
        routeData.forEach(entry => {
          //console.log('entry',entry);
          totalAmounts[entry.type] = (totalAmounts[entry.type] || 0) + entry.amount;
        });
        // Calculate the total amount across all types
        const totalAllTypes = Object.values(totalAmounts).reduce((total, amount) => total + amount, 0);

        // Calculate and print the percentage for each type
        var total = []
        for (const type in totalAmounts) {
          var color = ''
          if(type === 'openstamp'){
            color = 'rgb(121,118,172)'
          } else if(type === 'stampscan'){
            color = 'rgb(145,150,165)'
          }
          //const percentage = (totalAmounts[type] / totalAllTypes) * 100;
          //console.log(`Percentage volume of ${type}: ${percentage.toFixed(2)}%`);
          total.push({label:  type, value: totalAmounts[type],color:color})
        }
        setDataCandle({
          totalAllTypes: totalAllTypes,
          totalAmounts: total,
        })


        // Function to get the count of rows for each unique entry.type
        function countRowsPerType(data) {
          const counts = {};
          data.forEach(entry => {
            counts[entry.type] = (counts[entry.type] || 0) + 1;
          });
          return counts;
        }
        const rowCountsPerType = countRowsPerType(routeData);

        //console.log("Number of rows for each unique entry type:");
        var resultCountsPerType = []
        var resultTotal = 0
        for (const type in rowCountsPerType) {
          //console.log(`${type}: ${rowCountsPerType[type]}`);
          //resultCountsPerType[type] = rowCountsPerType[type]
          var color = ''
          if(type === 'openstamp'){
            color = 'rgb(121,118,172)'
          } else if(type === 'stampscan'){
            color = 'rgb(145,150,165)'
          }
          resultCountsPerType.push({
            label:  type,
            value: rowCountsPerType[type],
            color: color
          })
          resultTotal += rowCountsPerType[type]
        }
        setDataCandleCount({
          totalTxs:resultTotal,
          countTxs:resultCountsPerType
        })



      return () => {
        // Here goes the code you wish to run on unmount
        //console.log('unmount');
      }
    }, [dataOrigin, checked]);

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    const children = (item, devise) => {

      var filteredData = dataOrigin.filter(e => e.time === item);
      //console.log('satoshi',satoshi);
      // Add the price property to each object
      const newData = filteredData.map(item => {
        // Calculate the product of amount and value
        const price = (item.value * satoshi).toFixed(4);
        const priceTotal = ((item.value * satoshi) * item.amount).toFixed(2);
        // Create a new object with the existing properties and the new info property
        const btcTotal = ((item.value * item.amount) / 100000000).toFixed(4);
        return { ...item, price, priceTotal, btcTotal };
      });

      if(newData.length > 0){
        return (
          <Fragment>
            <TableTxs data={newData} unit={devise}/>
          </Fragment>

        );
      }
    }
    //
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
    const isMobile = useMediaQuery(theme.breakpoints.up('xs'));

    var datoForPrice = []
    if(mode){
      datoForPrice = dataCandlestick
    } else {
      datoForPrice = dataPrice
    }

    const styleButtonGroup = (isMode) => {
      return {
        //borderColor: isMode ? "rgba(250, 250, 250, 0.4)" : 'rgba(250, 250, 250, 0.2)',
        backgroundColor: isMode ? theme.palette.mode === 'dark' ? "rgba(250, 250, 250, 0.1)" : "rgba(0, 0, 0, 0.1)" : 'rgba(250, 250, 250, 0)',
        //color: isMode ? 'rgba(250, 250, 250, 0.9)':'rgba(250, 250, 250, 0.4)'
        opacity: isMode ? 1 : 0.5
      }
    }
    const styleWidthHeight = {
        width:isSmall ? isMedium ? 50 : 45 : 40,
        height:isSmall ? isMedium ? 50 : 50 : 48
    }

    return (
          <Fragment>

            <div style={{position: matches ? 'relative' : 'absolute',
              left:0,
              top: matches ? 16 : isSmall ? isMedium ? 253 : 193 : 150,
              width: matches ? 1200 : '100%',
              display:'flex', alignItems:'start',justifyContent:'space-between',
            }}>


                <Grid container spacing={0} style={{
                  display:'flex', alignItems:'center',justifyContent:'space-between'
                }}>
                  <Grid item xs={12} sm={12} md={12} style={{display:"flex",paddingTop:0, paddingBottom:16,width:'100%',justifyContent:'space-between'}}>

                    <div style={{display:"flex",alignItems:'center'}}>
                        <ButtonGroup variant="outlined" aria-label="outlined button group" style={{marginLeft:matches ? 0 : isSmall ? isMedium ? 12 : 12 : 10}}>
                          <Button onClick={e => handleChangeInterval(e)}
                                  style={{ ...styleButtonGroup(interval === '1D'),...styleWidthHeight}} value={'1D'}>1D</Button>
                          <Button onClick={e => handleChangeInterval(e)}
                                  style={{...styleButtonGroup(interval === '4H'),...styleWidthHeight}} value={'4H'}>4H</Button>
                          <Button onClick={e => handleChangeInterval(e)}
                                  style={{...styleButtonGroup(interval === '1H'),...styleWidthHeight}} value={'1H'}>1H</Button>
                        </ButtonGroup>

                        <ButtonGroup variant="outlined" aria-label="outlined button group" style={{marginLeft:isSmall ? isMedium ? 16 : 8 : 8}}>
                          <Button onClick={e => handleChangeMode(1)}
                                  style={{ ...styleButtonGroup(!mode),...styleWidthHeight}} value={1}><ShowChartIcon onClick={e => handleChangeMode(1)} value={1}/></Button>
                          <Button onClick={e => handleChangeMode(2)}
                                  style={{...styleButtonGroup(mode),...styleWidthHeight}} value={2}><CandlestickChartIcon onClick={e => handleChangeMode(2)} value={2}/></Button>
                        </ButtonGroup>


                     </div>

                    <Exchanges handleChangeMarket={handleChangeMarket} matches={matches} isSmall={isSmall} isMedium={isMedium}/>
                  </Grid>


                  <Grid item xs={12} sm={12} md={12} style={{paddingBottom:16}}>
                    <Candlestick unit={unit} priceData={datoForPrice} volumeData={dataVolume} themeChart={mode} isSmall={isSmall} isMedium={isMedium} />
                  </Grid>


                  <Grid item xs={12} sm={12} md={12} style={{
                    marginBottom:16,
                    marginLeft:matches ? 0 : isSmall ? isMedium ? 12 : 12 : 10,
                    marginRight:matches ? 0 : isSmall ? isMedium ? 12 : 12 : 10
                  }}>

                    <Accordion elevation={2} sx={{maxWidth: 1200}} variant="outlined">

                      <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        style={{width: '100%'}}
                      >
                      <Grid  container style={{ width: '100%', display:'flex', justifyContent: 'space-between',flexDirection: 'row'}}>
                        <Grid item xs={6} sm={6} md={3} style={{padding:4}}>
                          <Typography color={'textSecondary'}>Date </Typography>
                          <Typography sx={{ width: '100%', flexShrink: 0, fontSize:isSmall ? 15 : 13 }}>
                            {dataRange[0]}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} style={{padding:4}}>
                          <Typography color={'textSecondary'}>Holder count </Typography>
                          <Typography sx={{ color: 'text.primary',fontSize:isSmall ? 15 : 13 }}>{orders.holdersCount ? orders.holdersCount.toLocaleString('en-US') : '__'}</Typography>
                        </Grid>

                      <Grid item xs={6} sm={6} md={3} style={{padding:4}}>
                        <Typography color={'textSecondary'}>Market Orders </Typography>
                        <Typography sx={{ color: 'text.primary',fontSize:isSmall ? 15 : 13 }}>{(orders.openCount + orders.scanCount).toLocaleString('en-US')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} style={{padding:4}}>
                          <Typography color={'textSecondary'}>Amount Orders  </Typography>
                          <Typography sx={{ color: 'text.primary',fontSize:isSmall ? 15 : 13 }}>{orders.totalAmount.toLocaleString('en-US')}</Typography>
                        </Grid>

                      </Grid>
                      </AccordionSummary>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{
                    marginBottom:16,
                    marginLeft:matches ? 0 : isSmall ? isMedium ? 12 : 12 : 10,
                    marginRight:matches ? 0 : isSmall ? isMedium ? 12 : 12 : 10
                  }}>

                    <Accordion variant="outlined" elevation={2} sx={{maxWidth: 1200}} expanded={expanded === (`panel`+`${1}`)} onChange={handleChange((`panel`+`${1}`))}>

                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >

                        <Grid container>

                          <Grid item xs={6} sm={6} md={5} style={{display:'flex'}}>
                            {dataCandle.totalAmounts && dataCandle.totalAmounts.length > 0 ?
                                <PieChart
                                  margin={ {left: 100 }}
                                  series={[
                                    {
                                      data: dataCandle.totalAmounts,
                                      innerRadius: 80,
                                      outerRadius: 100,
                                      arcLabelMinAngle: 45,
                                      highlightScope: { faded: 'global', highlighted: 'item' },
                                      faded: { innerRadius: 60, additionalRadius: -5, color: 'gray' },
                                    },
                                  ]}
                                  legend={{ hidden: true }}
                                  width={300}
                                  height={matches ? 220 : isSmall ? isMedium ? 210 : 200 : 170}
                                >
                                <PieCenterLabel>{dataCandle.totalAllTypes.toLocaleString('en-US')}</PieCenterLabel>
                                </PieChart>
                                :
                                <PieChart
                                      margin={ {left: 100 }}
                                      series={[
                                        {
                                          innerRadius: 80,
                                          outerRadius: 100,
                                          data: [{value: 100, color: 'gray'}],
                                        },
                                      ]}
                                      width={300}
                                      height={matches ? 220 : isSmall ? isMedium ? 210 : 200 : 170}
                                      legend={{ hidden: true }}
                                  >
                                  <PieCenterLabel>0</PieCenterLabel>
                                </PieChart>
                              }
                          </Grid>

                          <Grid item xs={6} sm={6} md={5} style={{display:'flex'}}>
                            {dataCandleCount && dataCandleCount.totalTxs > 0 && dataCandleCount.countTxs.length > 0 ?
                                    <PieChart
                                      margin={ {left:  90 }}
                                      series={[
                                        {
                                          innerRadius: 80,
                                          outerRadius: 100,
                                          arcLabelMinAngle: 45,
                                          data: dataCandleCount.countTxs,
                                          highlightScope: { faded: 'global', highlighted: 'item' },
                                          faded: { innerRadius: 60, additionalRadius: -5, color: 'gray' },
                                        },
                                      ]}
                                      width={300}
                                      height={matches ? 220 : isSmall ? isMedium ? 210 : 200 : 170}
                                      legend={{ hidden: true }}
                                  >

                                <PieCenterLabel>{dataCandleCount.totalTxs.toLocaleString('en-US')} txs</PieCenterLabel>
                                </PieChart>
                                :
                                <PieChart
                                      margin={ {left: 90 }}
                                      series={[
                                        {
                                          innerRadius: 80,
                                          outerRadius: 100,
                                          data: [{value: 100, color: 'gray'}],
                                        },
                                      ]}
                                      width={300}
                                      height={matches ? 220 : isSmall ? isMedium ? 210 : 200 : 170}
                                      legend={{ hidden: true }}
                                  >
                                  <PieCenterLabel>0 txs</PieCenterLabel>
                                </PieChart>
                              }
                          </Grid>
                        </Grid>

                      </AccordionSummary>

                      <AccordionDetails className={`scrollbar-hidden`} style={{height: 'calc(100vh - 1200px)',overflowY:'scroll', overflowX:'hidden'}}>

                      {children(dataRange[0],unit)}



                      </AccordionDetails>
                    </Accordion>

                  </Grid>
                </Grid>

          	</div>






            <div style={{marginTop: 50}}>


              </div>


        </Fragment>
    );
}
