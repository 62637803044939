import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { useTheme } from '@mui/material/styles';

export default function Candlestick(props) {
  const theme = useTheme();

  const chartContainerRef = useRef();
  const chart = useRef();
  const resizeObserver = useRef();
  //
  const candleSeriesRef = useRef();
  const volumeSeriesRef = useRef();


  // Get the current users primary locale
  //const currentLocale = window.navigator.languages[0];
  //const myPriceFormatter = Intl.NumberFormat(currentLocale).format;

  useEffect(() => {
    if(chart.current){
      chart.current.remove();
    }

    if(theme){
      chart.current = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: props.isSmall ? props.isMedium ? 450 : 360 : 300,//chartContainerRef.current.clientHeight,
        layout: {
          backgroundColor: theme.palette.background,
          textColor: theme.palette.text.secondary,
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        rightPriceScale:{
            scaleMargins: {
                top: 0.05,
                bottom: 0.5,
            },
            borderVisible: false,
        },
        localization: {
            //priceFormatter: myPriceFormatter,
        },
      });

      var testIsAera = props.themeChart
      if(testIsAera){

        candleSeriesRef.current = chart.current.addCandlestickSeries({
          upColor: '#26a69a',
          downColor: '#ef5350',
          //borderDownColor: '#ff4976',
          //borderUpColor: '#4bffb5',
          wickDownColor: '#838ca1',
          wickUpColor: '#838ca1',
        });
        candleSeriesRef.current.applyOptions({
            priceFormat: {
                type: 'price',
                precision: props.unit ? 2 : 4,
                minMove: props.unit ? 0.01 : 0.0001,
            },
        });

      } else {
        candleSeriesRef.current = chart.current.addAreaSeries({
          lineColor: '#2962FF', topColor: '#2962FF', bottomColor: 'rgba(41, 98, 255, 0.08)'
        });
        candleSeriesRef.current.applyOptions({
            priceFormat: {
                type: 'price',
                precision: props.unit ? 2 : 4,
                minMove: props.unit ? 0.01 : 0.0001,
            },
        });
      }

      candleSeriesRef.current.setData(props.priceData);

      volumeSeriesRef.current = chart.current.addHistogramSeries({
        color: '#182233',
        lineWidth: 2,
        priceFormat: {
          type: 'volume',
        },
        overlay: true,
        scaleMargins: {
          top: 0.8,
          bottom: 0,
        },
      });
      volumeSeriesRef.current.setData(props.volumeData);
    }
    //console.log('props.themeChart',testIsAera);
  }, [props.priceData,props.themeChart,props.isSmall,props.isMedium, props.unit]);


  // Resize chart on container resizes.
 useEffect(() => {
   resizeObserver.current = new ResizeObserver(entries => {
     const { width, height } = entries[0].contentRect;
     chart.current.applyOptions({ width, height });
     setTimeout(() => {
       chart.current.timeScale().fitContent();
     }, 0);
   });

   resizeObserver.current.observe(chartContainerRef.current);

   return () => resizeObserver.current.disconnect();
 }, []);

  return (
      <div ref={chartContainerRef} className="chart-container" style={{flex: 1}} />
  );
}
