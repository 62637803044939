import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
//import * as d3 from "d3";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Fade from '@mui/material/Fade';

export default function TableStatsHead(props) {


  var largeTable = {
    paddingLeft:0,
    paddingTop:0, paddingBottom:0,
  }


  //console.log('chargeur',chargeur);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  //
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.up('xs'));




  var display = {
    display: isMedium ? 'table-cell' : 'none'
  }
  var hidenMobile = {
    display:  isSmall ? 'table-cell' : 'none'
  }




  return (

          <TableHead>
            <TableRow>
              <TableCell align="left" style={{color: theme.palette.text.secondary}}>#</TableCell>
              <TableCell align="left" style={{...largeTable, color: theme.palette.text.secondary}}>Logo</TableCell>
              <TableCell align="left" style={{...largeTable, color: theme.palette.text.secondary}}>Name</TableCell>
              <TableCell align="center" style={{...largeTable, color: theme.palette.text.secondary}}>Price</TableCell>
              <TableCell align="center" style={{...largeTable, ...display,color: theme.palette.text.secondary}}>4h %</TableCell>
              <TableCell align="center" style={{...largeTable,color: theme.palette.text.secondary}}>1d %</TableCell>
              <TableCell align="center" style={{...largeTable, ...display,color: theme.palette.text.secondary}}>7d %</TableCell>
              <TableCell align="right" style={{...largeTable,color: theme.palette.text.secondary}}>Market Cap</TableCell>

              <TableCell align="right" style={{...largeTable, ...display,color: theme.palette.text.secondary}}>Volume(24h)</TableCell>
              <TableCell align="right" style={{...largeTable,...display,color: theme.palette.text.secondary}}>Circulating Supply</TableCell>
              <TableCell align="center" style={{...largeTable,...hidenMobile,color: theme.palette.text.secondary}}>Last 7 Days</TableCell>
            </TableRow>

          </TableHead>

  );
}
