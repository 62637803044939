import React, {useEffect} from 'react';
import { compose } from 'recompose';
//
import { graphql, withApollo, ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import {Client} from '../client/Client'
//
import  Home  from './Home';
//


export const SERVER_QUERY = gql`
  query caps($first: Int!, $skip: Int!) {
    caps(first: $first, skip: $skip) {
      tokenId
      block_index
      name
      tx
      value
      price
      percentMove_4H
      percentMove_4H_USD
      percentMove_1D
      percentMove_1D_USD
      percentMove_7D
      percentMove_7D_USD
      marketcap
      marketcap_USD
      totalVolume1D
      totalVolume1D_USD
      totalSupply
      data7 {
        value
        date
      }
      data7_USD {
        value
        date
      }
    }
  }
`



const data = graphql(SERVER_QUERY, {
  options: ownProps => {
    //
    return ({
      variables: {
        first:15,
        skip:0,
      }
    })
  },
  client: Client,
  props: (props) => {

      return {
        data: props.data,
        loadMoreCaps: () => {

          return props.data.fetchMore({
            variables: {
              first:10,
              skip:parseInt(props.data.caps.length),
            },
            updateQuery(previousResult, { fetchMoreResult }) {

              const prevMessageFeed = previousResult;
              const newMessageFeed = fetchMoreResult;
              console.log('previousResult',previousResult);
              console.log('fetchMoreResult',fetchMoreResult);

              const newCollectionsData = {
                caps: [
                  ...prevMessageFeed.caps,
                  ...newMessageFeed.caps,
                ],
              }
              return newCollectionsData;

            }
        });
      }
    };
  }
})


/******************************************************************************/
const Container = compose(
  data,
  withApollo,
)(Home);

export default function HomeContainer(props) {

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: props.router.location.pathname + props.router.location.search,
    });
  }, []);
  return (
    <ApolloProvider client={Client}>
      <Container {...props}/>
    </ApolloProvider>
  );
}

/******************************************************************************/
