import { ApolloClient } from 'apollo-client';
import { split, HttpLink } from '@apollo/client';
import { InMemoryCache } from 'apollo-cache-inmemory'
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

const httpLink = new HttpLink({
  //uri: 'http://localhost:3001/graphql'
  uri: 'https://stampmarketcap2.meteorapp.com/graphql'
});

const wsLink = new WebSocketLink({
  //uri: 'wss://localhost:3001/graphql',
  uri: 'wss://stampmarketcap2.meteorapp.com/graphql',
  options: {
    reconnect: false,
  }
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const Client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache()
});
